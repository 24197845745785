<next-action-header
  [labelKey]="'VEHICLE_ORDER.TITLE'"
  [actionList]="VehicleOrderConstant.actions"
  [testIdDataAttr]="VehicleOrderManagerTestIds.HEADER"
></next-action-header>
<vehicle-order-manager-filter
  [filterValues]="filterParams"
  [selection]="selectedOrders"
  (filterChange)="handleFilterChange($event)"
  (modifyAction)="handleModifyVehicleOrder()"
  (cancelAction)="handleCancelVehicleOrderSelection()"
  [hasExtraFilter]="hasExtraFilter"
  [testIdDataAttr]="VehicleOrderManagerTestIds.FILTER_TOOLBAR"
></vehicle-order-manager-filter>
<next-table
  *ngIf="vehicleOrderList$ | async as vehicleOrderList"
  [data]="vehicleOrderList"
  [dataKey]="VehicleOrderProps.ID"
  [columns]="VehicleOrderConstant.tableListConfig"
  [globalFilterFields]="VehicleOrderConstant.globalFilter"
  [filterValues]="filterParams"
  [withSelection]="true"
  [testIdDataAttr]="VehicleOrderManagerTestIds.TABLE"
  [selectedRows]="selectedOrders"
  (selectionChange)="selectedOrders = $event"
  (sortPageChange)="sortPageChangeHandle($event)"
  (pageChange)="pageChangeHandle($event)"
  (filteredValues)="handleFilterValueChange($event)"
  (clickViewActionEvt)="viewOrderDetails($event)"
>
  <ng-template pTemplate="footerTemplateRef">
    <vehicle-order-totals *ngIf="filteredValues" [vehicleOrderList]="filteredValues"></vehicle-order-totals>
  </ng-template>
</next-table>
<vehicle-order-bulk-modify [vehicleOrderList]="selectedOrders" [isOpen]="isBulkModifyOpen" (cancel)="handleCancelBulkModify()"></vehicle-order-bulk-modify>
