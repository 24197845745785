<next-dialog
  [visible]="isOpen"
  [titleKey]="'VEHICLE_ORDER.BULK_MODIFY.TITLE'"
  [submitLabelKey]="'VEHICLE_ORDER.BULK_MODIFY.SUBMIT'"
  [hideDismiss]="true"
  [isSubmitDisabled]="formGroup.invalid"
  (visibleChange)="cancel.emit()"
  (submitClick)="handleSubmit()"
  (cancelClick)="cancel.emit()"
>
  <div dialogContent>
    <div class="vehicle-order-bulk-modify__summary">{{ 'VEHICLE_ORDER.BULK_MODIFY.SUMMARY' | translate }}</div>
    <div class="vehicle-order-bulk-modify__section-container">
      <div class="vehicle-order-bulk-modify__section">
        <div class="vehicle-order-bulk-modify__section-title">
          {{ 'VEHICLE_ORDER.BULK_MODIFY.ORDERS_TO_MODIFY' | translate }}
        </div>
        <div>
          <next-table
            [dataKey]="VehicleOrderProps.ID"
            [data]="vehicleOrderList"
            [columns]="VehicleOrderConstant.modifyOrderTableListConfig"
          ></next-table>
        </div>
      </div>
      <div class="vehicle-order-bulk-modify__section">
        <div class="vehicle-order-bulk-modify__section-title">
          {{ 'VEHICLE_ORDER.BULK_MODIFY.ASSIGN_NEW' | translate }}
        </div>
        <div *ngIf="formGroup">
          <input-container
            *ngIf="formGroup | deepControl: [ModifyOrderProps.TARGET_DISTRIBUTOR] as distributorControl"
            [control]="distributorControl"
            [labelKey]="'VEHICLE_ORDER.BULK_MODIFY.TARGET_DISTRIBUTOR'"
            class="vehicle-order-filter__item"
          >
            <next-dropdown
              [control]="distributorControl"
              [itemsObs$]="distributorList$"
              [name]="ModifyOrderProps.TARGET_DISTRIBUTOR"
              [testIdDataAttr]="ModifyOrderProps.TARGET_DISTRIBUTOR"
              inputElement
            ></next-dropdown>
          </input-container>
          <input-container
            *ngIf="formGroup | deepControl: [ModifyOrderProps.TARGET_PRODUCT_LINE] as productLineControl"
            [control]="productLineControl"
            [labelKey]="'VEHICLE_ORDER.BULK_MODIFY.TARGET_PRODUCT_LINE'"
            class="vehicle-order-filter__item"
          >
            <next-dropdown
              [control]="productLineControl"
              [itemsObs$]="productLineList$"
              [name]="ModifyOrderProps.TARGET_PRODUCT_LINE"
              [testIdDataAttr]="ModifyOrderProps.TARGET_PRODUCT_LINE"
              inputElement
            ></next-dropdown>
          </input-container>
        </div>
      </div>
    </div>
  </div>
</next-dialog>
