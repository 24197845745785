import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DropdownList } from '@components/atoms/form-inputs/components/next-dropdown/types/dropdown.type';
import { DistributorManagerService } from '@pages/distributor/services/distributor-manager.service';
import { DistributorService } from '@pages/distributor/services/distributor.service';
import { SourceLocationService } from '@pages/distributor/services/source-location.service';
import { CurrencyService } from '@pages/general-maintenance/pages/currency/services/currency.service';
import { VehicleOrderFilterProps } from '@pages/new-business/pages/vehicle-order/enums/vehicle-order.enum';
import { NextFormHelper } from '@utils/core/next-form.helper';
import { GenericObject } from '@utils/models/Types';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'vehicle-order-manager-filter',
  templateUrl: './vehicle-order-manager-filter.component.html',
  styleUrl: './vehicle-order-manager-filter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VehicleOrderManagerFilterComponent implements OnInit {
  @Input() filterValues: GenericObject = {};
  @Input() selection: any[];
  @Input() hasExtraFilter: boolean = false;
  @Output() filterChange = new EventEmitter<any>();
  @Output() modifyAction = new EventEmitter<any>();
  @Output() cancelAction = new EventEmitter<any>();

  extraFormGroup: FormGroup;
  distributorList$: Observable<DropdownList>;
  productLineList$: Observable<DropdownList>;
  sourceLocationList$: Observable<DropdownList>;
  vehicleCategoryList$: Observable<DropdownList>;
  vehicleTypeList$: Observable<DropdownList>;
  currencyList$: Observable<DropdownList>;
  orderStatusList$: Observable<DropdownList>;
  camList$: Observable<DropdownList>;

  protected readonly VehicleOrderFilterProps = VehicleOrderFilterProps;

  constructor(
    private _fb: FormBuilder,
    private _distributorService: DistributorService,
    private _currencyService: CurrencyService,
    private _sourceLocationService: SourceLocationService,
    private _distributorManagerService: DistributorManagerService
  ) {}

  ngOnInit(): void {
    this._initializeFormConfig();
    this._defineFormGroup();
  }

  private _initializeFormConfig() {
    this.distributorList$ = this._distributorService.getDropdownList();
    this.productLineList$ = this._distributorManagerService.getProductLinesTypesDropdownList();
    this.currencyList$ = this._currencyService.getDropdownList();
    this.sourceLocationList$ = this._sourceLocationService.getDropdownList();
    this.vehicleCategoryList$ = of([]);
    this.vehicleTypeList$ = of([]);
    this.orderStatusList$ = of([]);
    this.camList$ = of([]);
  }

  private _defineFormGroup() {
    const groupValues = NextFormHelper.buildGroupValues(
      [
        VehicleOrderFilterProps.DISTRIBUTOR,
        VehicleOrderFilterProps.ORDER_DATE_FROM,
        VehicleOrderFilterProps.ORDER_DATE_TO,
        VehicleOrderFilterProps.PRODUCT_LINE,
        VehicleOrderFilterProps.SOURCE_LOCATION,
        VehicleOrderFilterProps.VEHICLE_MODEL,
        VehicleOrderFilterProps.CURRENCY,
        VehicleOrderFilterProps.ORDER_STATUS,
        VehicleOrderFilterProps.CAM
      ],
      { ...this.filterValues }
    );

    this.extraFormGroup = this._fb.group(groupValues);
  }
}
